import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center black bold>
            <CFLink
              target="_blank"
              href="https://www.facebook.com/mrsushiprincegeorge/"
            >
              <CFImage w="50px" pr="10px" src={facebook} alt="Mr Sushi Yelp" />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter black bold>
            <CFLink
              target="_blank"
              href="https://www.facebook.com/mrsushiprincegeorge/"
            >
              <CFImage w="45px" pr="10px" src={facebook} alt="Mr Sushi Yelp" />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}

import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${mobileHero}) bottom/ cover no-repeat`}
          column
          justifyBetween
        >
          <Header />
          <CFView column justifyStart alignCenter bg="rgba(0,0,0,0.9)">
            <CFView white xBold style={{ fontSize: 35 }}>
              NOW TAKING
            </CFView>
            <CFView white xBold style={{ fontSize: 35 }}>
              ONLINE ORDERS
            </CFView>
            <CFView pulsate mt="5px" textCenter>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="665px"
          w="100%"
          image={`url(${hero}) 35% 0  / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="rgba(0,0,0,0.9)" ph="25px">
            <CFView bold white style={{ fontSize: 30 }}>
              Welcome To Mr Sushi in Prince George !
            </CFView>
            <CFView ml="30px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
